import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import GameLayout from "./pages/GameLayout";
import TreasureHunt from "./pages/TreasureHunt";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<GameLayout />} /> */}
        <Route path="/" element={<TreasureHunt />} />
      </Routes>
    </Router>
  );
}

export default App;
