import React, { useEffect, useState } from "react";

export default function AlertModel({ text, duration = 2000, setIsAleart }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    // Set a timeout to hide the alert after the specified duration
    const timer = setTimeout(() => {
      setVisible(false);
      setIsAleart(false);
    }, duration);

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [duration]);

  // If the alert is not visible, return null (nothing is rendered)
  if (!visible) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-100 text-white p-4 rounded-lg shadow-lg w-full max-w-md m-10">
        <p className="text-red-500 text-center">{text}</p>
      </div>
    </div>
  );
}
